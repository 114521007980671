import React from 'react';
import { withRouter, Switch, Route } from "react-router-dom";
import Home from "./containers/Home";
import About from "./containers/About";
import './App.css';

function App() {
  return (
    <div className="App">
      <Switch>
        {/* If the current URL is /about, this route is rendered
            while the rest are ignored */}
        <Route path="/about">
          <About />
        </Route>

        {/* If none of the previous routes render anything,
            this route acts as a fallback.

            Important: A route with path="/" will *always* match
            the URL because all URLs begin with a /. So that's
            why we put this one last of all */}
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  );
}

export default withRouter(App);;
