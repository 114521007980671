import React from 'react';
import { withRouter } from "react-router-dom";
// import './App.css';

function About(props) {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Maker Studio</h1>

        <h3> <span role="img" aria-labelledby="light bulb">💡</span> Ideas Lab </h3>

        We build, develop, and launch products for the internet.

        <br />
        <br />

        <a href="https://makerstudio.tech/privacypolicy.pdf" target="blank" rel="noopener noreferrer" >Privacy Policy</a>
        <a href="mailto:hello@makerstudio.tech" target="blank" rel="noopener noreferrer" >hello@makerstudio.tech </a>

      </header>
    </div>
  );
}

export default withRouter(About);;
