import React from 'react';
import { Link, withRouter } from "react-router-dom";
// import './App.css';

function Home(props) {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Maker Studio</h1>

        Maker Studio is an internet company.

        <br />
        <br />

        Our Products:

        <a href="https://airlane.tech" target="blank"  rel="noopener noreferrer"> Airlane </a>

        <br />
        <br />
        <Link to="/about"> About us </Link>
        <a href="https://makerstudio.tech/privacypolicy.pdf" target="blank" rel="noopener noreferrer" >Privacy Policy</a>
		<a href="mailto:hello@makerstudio.tech" target="blank" rel="noopener noreferrer" >hello@makerstudio.tech </a>

      </header>
    </div>
  );
}

export default withRouter(Home);;
